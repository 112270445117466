import React from 'react'
import {Link as GLink} from 'gatsby'
import {Container, Box, Flex} from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import PoweredByGatsby from '@solid-ui-components/PoweredByGatsby'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

import AENOR from '../../../pages/assets/AENOR.webp'
import AENOR9001 from '../../../pages/assets/ISO-9001.png'
import ENS from '../../../pages/assets/ense_160-1.webp'

import CertificadoAENOR from '../../../pages/assets/pdf/CertificadoAENOR.pdf'
import CertificadoAENOR9001 from '../../../pages/assets/pdf/CertificadoAENOR9001.pdf'
import CertificadoENS from '../../../pages/assets/pdf/CertificadoENS.pdf'

import * as block01Styles from './Block01.module.css'

const styles = {
    wrapper: {
        position: `relative`,
        bg: `footerBg`
    },
    footer: {
        flexDirection: [`column`, `row`],
        justifyContent: `space-between`,
        alignItems: [`center`, `flex-start`],
        py: 5
    }
}

const FooterBlock01 = ({content: {images, collection}}) => {
    return (
        <Box sx={styles.wrapper}>
            <Container title='' title='' px='4'>
                <Flex sx={styles.footer}>
                    <Box sx={{minWidth: 200}}>
                        <Box pb='1' mb='2' mt={[4, 0]}>
                            <GLink to='/'>
                                <ContentImages content={{images}} imageEffect='fadeIn'/>
                            </GLink>
                        </Box>
                        <Box pt='2' mb={[2, 4]}>
                            © {new Date().getFullYear()} All Rights Reserved.

                        </Box>
                        <Box pt="2" mb={[2, 4]}>
                            <a href={CertificadoENS} download>
                                <img className={block01Styles.maxWidthImg} src={ENS} alt="ens logo" />
                            </a>
                            <a href={CertificadoAENOR} download>
                                <img className={block01Styles.maxWidthImg} src={AENOR} alt="aenor logo" />
                            </a>
                            <a href={CertificadoAENOR9001}>
                                <img className={block01Styles.maxWidthImg} src={AENOR9001} alt="aenor logo" />
                            </a>
                        </Box>
                    </Box>
                    {collection?.map(
                      ({ text, buttons }, index) =>
                        buttons && (
                          <Box key={`item-${index}`} mb='3'>
                                    <ContentText
                                        content={text?.[0]}
                                        variant='h5'
                                        sx={{display: [`none`, `block`]}}
                                    />
                                    <ContentButtons
                                        content={buttons}
                                        variant='vertical'
                                        wrapperStyles={{flexDirection: [`column`, `column`]}}
                                    />
                                </Box>
                            )
                    )}
                </Flex>
            </Container>
        </Box>
    )
}

FooterBlock01.defaultProps = {
    menuJustify: `flex-end`
}

export default WithDefaultContent(FooterBlock01)
